import {
  FaExternalLinkAlt,
  FaFileInvoice,
  FaInstagram,
  FaLink,
  FaMoneyCheck,
  FaUserPlus
} from 'react-icons/fa'
import { PageContainer } from './styles'

interface IFinishedPage {
  getPdfBoleto: (financialMovimentPaymentId: number, barCode: string) => void
  previewContract: () => void
  createdFinancialMoviments: IFinancialMoviment[]
}
export const FinishedPage = ({
  getPdfBoleto,
  previewContract,
  createdFinancialMoviments
}: IFinishedPage): JSX.Element => {
  return (
    <PageContainer>
      <div>
        <h1>Obrigado!</h1>
        <p>Sua compra foi concluída!</p>
        <p>Ja estamos cuidando de tudo…</p>
        <p>Parabéns por se importar tanto!</p>
        <p>Você é fantástico!</p>
        <p></p>
        <p>
          <b>O contrato e a cobrança foram enviados por e-mail e whatsapp.</b>
        </p>
        <div>
          <button
            className="btn btn-primary"
            style={{ margin: '15px', padding: '8px 10px' }}
            onClick={() => {
              const url = new URL(window.location.href)
              const client = url.searchParams.get('client')
              const studentUrl = `${url.origin}${url.pathname}?client=${client}&step=2`
              window.location.replace(studentUrl)
            }}
          >
            <FaUserPlus style={{ marginRight: '5px' }} />
            Cadastrar novo estudante
          </button>
          <button
            className="btn btn-info"
            style={{ margin: '15px', padding: '8px 10px' }}
            onClick={() => {
              window.open('https://lp.merenderia.com.br/boas-vindas', '_blank')
            }}
          >
            <FaExternalLinkAlt style={{ marginRight: '5px' }} />
            Clique para personalizar as refeições
          </button>
        </div>
        <div style={{ marginTop: '100px' }}>
          <a
            href="https://www.merenderia.com.br"
            target="_blank"
            rel="noreferrer"
          >
            <FaLink style={{ marginRight: '5px' }} />
            Site
          </a>
          <a
            href="https://instagram.com/merenderia?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram style={{ marginRight: '5px' }} />
            Instagram
          </a>
        </div>
      </div>
    </PageContainer>
  )
}
