export type daysOfWeek =
  | 'Domingo'
  | 'Segunda-feira'
  | 'Terça-feira'
  | 'Quarta-feira'
  | 'Quinta-feira'
  | 'Sexta-feira'
  | 'Sábado'

export const daysOfWeekIndex = {
  Domingo: 0,
  'Segunda-feira': 1,
  'Terça-feira': 2,
  'Quarta-feira': 3,
  'Quinta-feira': 4,
  'Sexta-feira': 5,
  Sábado: 6
}

export function getFirstWorkingDay(limit: number) {
  const today = new Date()
  let final = new Date()
  const newDate = new Date()
  newDate.setDate(today.getDate() + limit)
  final = newDate
  if (newDate.getDay() === 6) {
    final.setDate(newDate.getDate() + 2)
  } else if (newDate.getDay() === 0) {
    final.setDate(newDate.getDate() + 1)
  }
  return final
}

export const getWorkingDaysByFrequency = (
  startDate: Date,
  endDate: Date,
  frequency: daysOfWeek[]
): number => {
  const weekDaysOfFrequency = frequency.map(weekday => daysOfWeekIndex[weekday])
  let result = 0

  const currentDate = new Date(startDate.getTime())
  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    const weekDay = currentDate.getDay()
    if (weekDaysOfFrequency.includes(weekDay)) {
      result += 1
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return result
}

export const addDays = (date: Date, days: number) => {
  const copy = new Date(date)
  copy.setDate(date.getDate() + days)
  return copy
}
